import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"
import { withAssetPrefix } from "gatsby"
import classNames from "classnames"

const backgroundImage = "/assets/images/top.jpg"

type Props = {
  classes: any
}

class IndexHero extends Component<Props> {
  componentDidMount() {
    const element = document.getElementById("index-hero-caption")
    setTimeout(() => {
      element.classList.add("fade-in")
    }, 500)
  }

  render() {
    const { classes } = this.props

    return (
      <div className={classes.background}>
        <div className={classes.container}>
          <div
            id="index-hero-caption"
            className={classNames(classes.title, "animation")}
          >
            女子サッカーを
            <br />
            文化に。
          </div>
        </div>
      </div>
    )
  }
}

const styles: any = (theme: any) => ({
  background: {
    maxWidth: 1280,
    position: "relative",
    marginBottom: 60,
  },
  canvas: {
    position: "absolute",
  },
  container: {
    backgroundImage: `url(${withAssetPrefix(backgroundImage)})`,
    maxWidth: 1180,
    display: "flex",
    alignItems: "center",
    height: 600,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "50% 100%",
    [theme.breakpoints.down("md")]: {
      height: 620,
      //backgroundPosition: '0% 90%',
    },
    [theme.breakpoints.down("sm")]: {
      //backgroundSize: '110%',
      //backgroundPosition: '0% 100%',
      height: 440,
    },
    [theme.breakpoints.down("xs")]: {
      //backgroundSize: '120%',
      //backgroundPosition: '0% 75%',
      height: 220,
    },
  },
  title: {
    fontFamily:
      '"Sawarabi Mincho",-apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif',
    position: "absolute",
    fontSize: 70,
    letterSpacing: 25,
    lineHeight: 1.4,
    color: "#FFF",
    right: 0,
    bottom: "20%",
    background: "rgba(30,54,92,0.7)",
    padding: 12,
    textAlign: "right",
    [theme.breakpoints.down("sm")]: {
      fontSize: 30,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
      letterSpacing: 20,
    },
  },
})

export default withStyles(styles)(IndexHero)
