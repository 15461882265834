import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"
import { withAssetPrefix, Link } from "gatsby"
import classNames from "classnames"
import LazyLoad from "react-lazyload"

const noImage = "/assets/images/noImage.jpg"

type Props = {
  classes: any
  data: any
  history: Object
}

type WPPost = {
  node: {
    id: string
    title: string
    excerpt: string
    date: string
    featured_media: {
      source_url: string | null
    }
  }
}

class IndexOurStories extends Component<Props> {
  _fomatDate(wordpressDate: string) {
    const dateObj = new Date(wordpressDate)
    return (
      dateObj.getFullYear() +
      "." +
      (dateObj.getMonth() + 1) +
      "." +
      dateObj.getDate()
    )
  }

  render() {
    const { classes, data } = this.props

    const stories = data.slice(0, 3)

    return (
      <section id="our-stories" className={classes.root}>
        <div className="title-wrapper">
          <h1 className="title">our stories</h1>
        </div>
        <div className={classes.stories}>
          {stories.map(({ node }: WPPost, index: number) => {
            var backgroundImg =
              node.featured_media && node.featured_media.source_url
                ? node.featured_media.source_url
                : withAssetPrefix(noImage)
            return (
              <div
                key={node.id}
                className={classNames(
                  classes.item,
                  "animation",
                  "slide-in_news"
                )}
              >
                <Link className={classes.item} to={`/stories/${node.id}`}>
                  <LazyLoad offset={200} once>
                    <p
                      className={classes.roundImg}
                      style={{ backgroundImage: "url(" + backgroundImg + ")" }}
                      alt={node.title}
                    />
                  </LazyLoad>
                  <div className={classes.title}>{node.title}</div>
                  <div className={classes.date}>
                    {this._fomatDate(node.date)}
                  </div>
                </Link>
              </div>
            )
          })}
        </div>
        <div className={classNames(classes.seeAll)}>
          <Link className={classes.seeAllLink} to={`/stories`}>
            <div className={classes.seeAllText}>
              SEE
              <br />
              ALL
            </div>
          </Link>
        </div>
      </section>
    )
  }
}

const styles = (theme: any) => ({
  root: {
    backgroundColor: "#FFF",
  },
  componentWrapper: {
    margin: "0 auto",
    maxWidth: 1280,
    paddingTop: 0,
  },
  stories: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  item: {
    textAlign: "center",
    marginBottom: 30,
  },
  roundImg: {
    borderRadius: "50%",
    width: "200px",
    height: "200px",
    marginBottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center",
    objectFit: "cover",
    [theme.breakpoints.down("sm")]: {
      width: "170px",
      height: "170px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100px",
      height: "100px",
    },
  },
  title: {
    fontFamily: "yasashisa",
    fontStyle: "normal",
    fontStretch: "normal",
    fontSize: 18,
    maxWidth: 210,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      maxWidth: 180,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 13,
      maxWidth: 110,
    },
  },
  date: {
    fontFamily: "Advent Pro",
    fontStyle: "normal",
    fontStretch: "normal",
    fontSize: 20,
    letterSpacing: 1.1,
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 15,
    },
  },
  seeAll: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: 50,
  },
  seeAllLink: {
    width: 70,
    height: 70,
    borderRadius: "50%",
    backgroundColor: "#1e365c",
    justifyContent: "center",
    display: "inline-block",
    textAlign: "center",
  },
  seeAllText: {
    textDecoration: "none",
    color: "#fff",
    fontWeight: "bold",
    fontFamily:
      '"Roboto", -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif',
    fontSize: 15,
    transform: "translateY(-50%)",
    position: "relative",
    top: "50%",
    lineHeight: "20px",
    marginBottom: 0,
  },
})

export default withStyles(styles)(IndexOurStories)
