import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"
import classNames from "classnames"
import LazyLoad from "react-lazyload"

type Props = {
  classes: any
}

class IndexWhatWeDo extends Component<Props> {
  render() {
    const { classes } = this.props

    return (
      <section id="what-we-do" className={classes.root}>
        <div
          className={classNames(
            "title-wrapper",
            classes.backgroundColorWhite,
            classes.containerSkew
          )}
        >
          <h1 className={classNames("title", classes.componentSkew)}>
            what we do
          </h1>
        </div>
        <div>
          <div
            className={classNames(
              classes.backgroundColorWhite,
              classes.containerSkew
            )}
          >
            <div
              className={classNames(
                classes.componentWrapper,
                classes.messageContainer1,
                classes.componentSkew
              )}
            >
              <div className={classes.image}>
                <LazyLoad offset={200} once>
                  <img src="/assets/images/web5.jpg" alt="普及活動" />
                </LazyLoad>
              </div>
              <div className={classes.message1}>
                <div
                  className={classNames(
                    classes.label,
                    "animation",
                    "slide-in-right"
                  )}
                >
                  -普及活動-
                </div>
                <div>
                  <h3>
                    "一人でも多くの女の子が大好きなサッカーに没頭できる環境が与えられるように"
                  </h3>
                  <div>
                    <p>
                      特に、13歳から15歳の㆗学生年代の受け皿が不足しているという問題を、㆞域と連携しながら解決して行きます。また国際感覚を養うため、サッカーを通じた国際交流の場を提供します。
                    </p>
                    <p>
                      みんなが平等にアクセスできる環境が整っている。それがなでケアが目指す理想です。
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.backgroundColorPink}>
            <div
              className={classNames(
                classes.backgroundColorPink,
                classes.containerSkew
              )}
            >
              <div
                className={classNames(
                  classes.componentWrapper,
                  classes.messageContainer1,
                  classes.componentSkew,
                  "pt40"
                )}
              >
                <div className={classes.image}>
                  <LazyLoad offset={200} once>
                    <img
                      src="/assets/images/web6.jpg"
                      alt="キャリアビルディング"
                    />
                  </LazyLoad>
                </div>
                <div className={classes.message1}>
                  <div
                    className={classNames(
                      classes.label,
                      "animation",
                      "slide-in-right"
                    )}
                  >
                    -キャリアビルディング-
                  </div>
                  <div>
                    <h3>
                      "プロ選手として、そして1人の人間として、自立して行きたい"
                    </h3>
                    <div>
                      <p>
                        なでケアでの活動が、現役そして引退後を含めた''キャリア構築''の場所となります。
                      </p>
                      <p>
                        勉強会を重ねることでマインドセットを変え、選手を超えて一人の女性、そして人間として自立して行きます。女性のロールモデルとなり、優しく勇気付ける事ができる存在になります。
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.backgroundColorPink}>
            <div
              className={classNames(
                classes.backgroundColorWhite,
                classes.containerSkew2
              )}
            >
              <div
                className={classNames(
                  classes.componentWrapper,
                  classes.messageContainer2,
                  classes.componentSkew2
                )}
              >
                <div className={classes.image}>
                  <LazyLoad offset={200} once>
                    <img src="/assets/images/web7.jpg" alt="社会課題の解決" />
                  </LazyLoad>
                </div>
                <div className={classes.message1}>
                  <div
                    className={classNames(
                      classes.label,
                      "animation",
                      "slide-in"
                    )}
                  >
                    -社会課題の解決-
                  </div>
                  <div>
                    <h3>
                      "「困ったらなでしこに」そんな風に、社会にもっと寄り添う存在になりたい"
                    </h3>
                    <div>
                      <p>
                        2011年、なでしこジャパンが日本人㆗に大きな感動と、どんな困難にも立ち向かう勇気を与え、そして、同時に沢山の勇気をもらいました。
                      </p>
                      <p>
                        いつでも手を差し伸べ、優しい気持ちで社会に寄り添える、そんな存在になります。
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={classNames(
              classes.backgroundColorWhite,
              classes.containerSkew2
            )}
          >
            <div
              className={classNames(
                classes.backgroundColor,
                classes.containerSkew3
              )}
            >
              <div
                className={classNames(
                  classes.componentWrapper,
                  classes.messageContainer2,
                  classes.componentSkew
                )}
              >
                <div className={classes.image}>
                  <LazyLoad offset={200} once>
                    <img
                      src="/assets/images/web8.jpg"
                      alt="EDUCATE & INSPIRE"
                    />
                  </LazyLoad>
                </div>
                <div className={classes.message1}>
                  <div
                    className={classNames(
                      classes.label,
                      "animation",
                      "slide-in"
                    )}
                  >
                    -EDUCATE & INSPIRE-
                  </div>
                  <div>
                    <h3>
                      "色々な人を巻き込みながら、社会に存在する「偏見」や「決めつけ」をなくしたい"
                    </h3>
                    <div>
                      <p>"サッカーは男のスポーツ"</p>
                      <p>
                        私たちは、こんな社会の偏見と向き合いながら競技を続けています。
                      </p>
                      <p>
                        誰もが自分が好きなことを思いっきり没頭できる社会。そんな社会を実現して行くために、色々な人を巻き込みながらメッセージを伝えます。
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

const styles = (theme: any) => ({
  root: {},
  componentWrapper: {
    margin: "0 auto",
    maxWidth: 1280,
    paddingTop: 0,
  },
  messageContainer1: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    paddingBottom: 40,
  },
  messageContainer2: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row-reverse",
    paddingTop: 40,
    paddingBottom: 40,
  },
  message1: {
    width: "50%",
    lineHeight: 1.6,
    letterSpacing: 1.6,
    overflow: "hidden",
    marginRight: "auto",
    marginLeft: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      paddingLeft: 20,
      paddingRight: 20,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  message2: {
    width: "50%",
    lineHeight: 1.6,
    letterSpacing: 1.6,
    marginRight: "auto",
    marginLeft: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      paddingLeft: 20,
      paddingRight: 20,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  image: {
    width: 500,
    marginRight: "auto",
    marginLeft: "auto",
    marginTop: 40,
  },
  label: {
    backgroundColor: "#eee",
    fontSize: 40,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 10,
    marginBottom: 40,
    fontFamily: "Sawarabi Mincho",
    lineHeight: 1.4,
    [theme.breakpoints.down("sm")]: {
      fontSize: 30,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 20,
    },
  },
  backgroundColor: {
    backgroundColor: "#f8d4d8",
  },
  backgroundColorWhite: {
    backgroundColor: "white",
  },
  backgroundColorPink: {
    backgroundColor: "#FD697A",
  },
  containerSkew: {
    transform: "skew(0deg, -3deg)",
  },
  containerSkew2: {
    transform: "skew(0deg, 3deg)",
  },
  containerSkew3: {
    transform: "skew(0deg, -6deg)",
  },
  componentSkew: {
    transform: "skew(0deg, 3deg)",
  },
  componentSkew2: {
    transform: "skew(0deg, -3deg)",
  },
})

export default withStyles(styles)(IndexWhatWeDo)
