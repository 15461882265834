import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"
import classNames from "classnames"

type Props = {
  classes: any
}

class IndexOrganization extends Component<Props> {
  render() {
    const { classes } = this.props

    const ballIcon = (
      <svg
        className={classes.ballIcon}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 496 512"
      >
        <path d="M483.8 179.4C449.8 74.6 352.6 8 248.1 8c-25.4 0-51.2 3.9-76.7 12.2C41.2 62.5-30.1 202.4 12.2 332.6 46.2 437.4 143.4 504 247.9 504c25.4 0 51.2-3.9 76.7-12.2 130.2-42.3 201.5-182.2 159.2-312.4zm-74.5 193.7l-52.2 6.4-43.7-60.9 24.4-75.2 71.1-22.1 38.9 36.4c-.2 30.7-7.4 61.1-21.7 89.2-4.7 9.3-10.7 17.8-16.8 26.2zm0-235.4l-10.4 53.1-70.7 22-64.2-46.5V92.5l47.4-26.2c39.2 13 73.4 38 97.9 71.4zM184.9 66.4L232 92.5v73.8l-64.2 46.5-70.6-22-10.1-52.5c24.3-33.4 57.9-58.6 97.8-71.9zM139 379.5L85.9 373c-14.4-20.1-37.3-59.6-37.8-115.3l39-36.4 71.1 22.2 24.3 74.3-43.5 61.7zm48.2 67l-22.4-48.1 43.6-61.7H287l44.3 61.7-22.4 48.1c-6.2 1.8-57.6 20.4-121.7 0z" />
      </svg>
    )

    return (
      <section id="organization" className={classes.root}>
        <div className="title-wrapper">
          <h1 className="title">organization</h1>
        </div>
        <div className={classes.componentWrapper}>
          <table className={classes.table}>
            <tbody>
              <tr>
                <th className={classes.tableTh}>{ballIcon} </th>
                <td className={classes.columnTitle}>組織名:</td>
                <td className={classes.tableTd}>⼀般社団法⼈ なでしこケア</td>
              </tr>
              <tr>
                <th className={classes.tableTh}>{ballIcon} </th>
                <th className={classes.columnTitle}>略称:</th>
                <td className={classes.tableTd}>なでケア</td>
              </tr>
              <tr>
                <th className={classes.tableTh}>{ballIcon} </th>
                <th className={classes.columnTitle}>創業:</th>
                <td className={classes.tableTd}>2019年7⽉</td>
              </tr>
              <tr>
                <th className={classes.tableTh}>{ballIcon} </th>
                <th className={classes.columnTitle}>所在㆞:</th>
                <td className={classes.tableTd}>
                  東京都千代田区飯田橋三丁目2番10-701号
                </td>
              </tr>
              <tr>
                <th className={classes.tableTh}>{ballIcon} </th>
                <th className={classes.columnTitle}>電話:</th>
                <td className={classes.tableTd}>03-6268-9672</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    )
  }
}

const styles: any = (theme: any) => ({
  root: {
    padding: 20,
    backgroundColor: "#FD697A",
  },
  componentWrapper: {
    margin: "0 auto",
    maxWidth: 1280,
    paddingTop: 0,
    textAlign: "center",
  },
  table: {
    border: "none",
    fontFamily: "Sawarabi Mincho",
    fontStyle: "normal",
    fontStretch: "normal",
    fontSize: 26,
    lineHeight: "40px",
    display: "flex",
    justifyContent: "center",
    marginTop: 10,
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
      lineHeight: "35px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 20,
      lineHeight: "20px",
    },
  },
  ballIcon: {
    height: 40,
    width: 40,
    fill: "white",
    [theme.breakpoints.down("sm")]: {
      height: 30,
    },
    [theme.breakpoints.down("xs")]: {
      height: 20,
    },
  },
  tableTh: {
    border: "none",
    paddingLeft: 0,
  },
  columnTitle: {
    border: "none",
    fontWeight: "normal",
    textAlign: "right",
    height: "10px",
    paddingRight: 0,
    paddingLeft: 0,
    whiteSpace: "nowrap",
  },
  tableTd: {
    border: "none",
    height: "10px",
  },
})

export default withStyles(styles)(IndexOrganization)
