import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"
import classNames from "classnames"
import LazyLoad from "react-lazyload"

type Props = {
  classes: any
}

class IndexMessage extends Component<Props> {
  render() {
    const { classes } = this.props

    return (
      <section id="message" className={classes.root}>
        <div className={classNames(classes.componentWrapper, "title-wrapper")}>
          <h1 className="title">message</h1>
        </div>
        <div className={classes.backgroundColor}>
          <div
            className={classNames(
              classes.componentWrapper,
              classes.messageContainer1
            )}
          >
            <div className={classes.image}>
              <LazyLoad offset={200} once>
                <img src="/assets/images/kumagai.jpg" alt="代表挨拶" />
              </LazyLoad>
            </div>
            <div className={classes.message1}>
              <h2>-代表挨拶-</h2>
              <div className={classes.profile}>
                <div>熊谷 紗希: オリンピック・リヨン（フランス）</div>
                <div>SAKI KUMAGAI: OLYMPIQUE LYON</div>
              </div>
              <div>
                <h3>"女子サッカーが多くの少女が夢見る職業に"</h3>
                <div>
                  <p>
                    20歳でドイツに渡り、ドイツ・フランスでプレーする㆗で、私はこのことを強く願うようになりました。私たちなでしこは、「なでしこみたいになりたい！」又は「なでしこを目指したい！」と、多くの女の子に思われるような存在であるべきだと思います。
                  </p>
                  <p>
                    そんな存在になるために、私たちなでしこに何ができるか。どうやって自分たちの価値を高めていけるか、ということを「なでケア」を通じでみんなで考え、行動していきたいと思っています。
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={classNames(
            classes.backgroundColorWhite,
            classes.containerSkew
          )}
        >
          <div
            className={classNames(
              classes.componentWrapper,
              classes.messageContainer2,
              classes.componentSkew
            )}
          >
            <div className={classes.image}>
              <LazyLoad offset={200} once>
                <img src="/assets/images/kondo.jpg" alt="理事挨拶" />
              </LazyLoad>
            </div>
            <div className={classes.message2}>
              <h2 className={classes.label}>-理事挨拶-</h2>
              <div className={classes.profile}>
                <div>近賀 ゆかり: サンフレッチェ広島レジーナ</div>
                <div>YUKARI KINGA: SANFRECCE HIROSHIMA REGINA</div>
              </div>
              <div>
                <h3>"なでしこが元気を届ける存在に"</h3>
                <div>
                  <p>
                    私はサッカーを通じて多くの人に出会い、様々な経験をしてきました。そして”なでしこジャパン”では、沢山の方に支えられ、応援して頂きました。
                  </p>
                  <p>
                    その㆗で自分たちには勇気や元気を届けられる力があるかもしれないと感じ、女子サッカーは様々な人達にパワーを送る存在であるべきだと思いました。
                  </p>
                  <p>
                    サッカーやスポーツの枠には観る・やる・携わる・触れる・支えるなど沢山の関わり方があります。
                  </p>
                  <p>
                    そこで『なでケア』を通じて、多くの方々にパワーを届けると共に憧れるような存在になっていきたいと思っています。
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={classNames(classes.backgroundColor, classes.containerSkew)}
        >
          <div
            className={classNames(
              classes.componentWrapper,
              classes.messageContainer1,
              classes.componentSkew,
              "pt40"
            )}
          >
            <div className={classes.image}>
              <LazyLoad offset={200} once>
                <img src="/assets/images/otaki.jpg" alt="創設者挨拶" />
              </LazyLoad>
            </div>
            <div className={classes.message1}>
              <h2 className={classes.label}>-創設者挨拶-</h2>
              <div className={classes.profile}>
                <div>大滝 麻未: JEF UNITED レディース</div>
                <div>AMI OTAKI: JEF UNITED LADIES</div>
              </div>
              <div>
                <h3>"ピッチの内外で活躍するなでしこを目指して"</h3>
                <div>
                  <p>
                    私は2011年W杯優勝の瞬間をテレビで見ていました。女子サッカーに対する考えが変わった瞬間でした。
                  </p>
                  <p>
                    「あの頃のように、社会の㆗で役割を果たしたい。」そんな想いを原動力に動き出すと、たくさんの優しい想いがあることに気づきました。そしてその想いが、私をたくさんの仲間と繋げてくれました。
                  </p>
                  <p>
                    この想いを形にしていきたい。そしてその過程で出会う人や、積むことのできる経験で、より豊かな人生を築いてほしい。多くの人を優しく支え、そして時には支えられながら成⻑して行く。そんななでしこになっていきたいです。
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

const styles: any = (theme: any) => ({
  root: {
    paddingTop: 40,
  },
  componentWrapper: {
    margin: "0 auto",
    maxWidth: 1280,
    paddingTop: 0,
  },
  messageContainer1: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  messageContainer2: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row-reverse",
    paddingTop: 40,
  },
  message1: {
    width: "60%",
    lineHeight: 1.6,
    letterSpacing: 1.6,
    marginRight: "auto",
    marginLeft: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      paddingLeft: 20,
      paddingRight: 20,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  message2: {
    width: "60%",
    lineHeight: 1.6,
    letterSpacing: 1.6,
    marginRight: "auto",
    marginLeft: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      paddingLeft: 20,
      paddingRight: 20,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  image: {
    width: 400,
    marginTop: 40,
    marginRight: "auto",
    marginLeft: "auto",
  },
  label: {
    //textAlign: "right",
  },
  backgroundColorWhite: {
    backgroundColor: "white",
  },
  backgroundColor: {
    backgroundColor: "#f8d4d8",
  },
  profile: {
    paddingBottom: 26,
    fontFamily:
      '"Noto Sans JP", -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif',
  },
  containerSkew: {
    transform: "skew(0deg, -3deg)",
  },
  componentSkew: {
    transform: "skew(0deg, 3deg)",
  },
})

export default withStyles(styles)(IndexMessage)
