import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"
import { withAssetPrefix, Link } from "gatsby"
import classNames from "classnames"
import isEmpty from "lodash/isEmpty"
import LazyLoad from "react-lazyload"

const noImage = "/assets/images/noImage.jpg"

type Props = {
  classes: any
  data: any
}

type WPPost = {
  node: {
    id: string
    title: string
    excerpt: string
    date: string
    featured_media: {
      source_url: string | null
    }
  }
}

class IndexNews extends Component<Props> {
  _formatDate(wordpressDate: string) {
    const dateObj = new Date(wordpressDate)
    return (
      dateObj.getFullYear() +
      "." +
      (dateObj.getMonth() + 1) +
      "." +
      dateObj.getDate()
    )
  }

  render() {
    const { classes, data } = this.props

    const firstNewsGroup = data.slice(0, 3)
    const secondNewsGroup = data.slice(3, 6)

    return (
      <section
        id="news"
        className={classNames(classes.root, classes.containerSkew)}
      >
        <div className={classNames(classes.componentSkew, "title-wrapper")}>
          <h1 className="title">news</h1>
        </div>
        <div
          className={classNames(
            classes.componentWrapper,
            classes.topNews,
            classes.componentSkew
          )}
        >
          {firstNewsGroup.map(({ node }: WPPost, index: number) => {
            return (
              <div
                key={node.id}
                className={classNames("animation", "slide-in_news")}
              >
                <Link className={classes.imgSize} to={`/news/${node.id}`}>
                  <LazyLoad offset={200} once>
                    <img
                      className={classes.img}
                      src={
                        node.featured_media && node.featured_media.source_url
                          ? node.featured_media.source_url
                          : withAssetPrefix(noImage)
                      }
                      alt={node.title}
                    />
                  </LazyLoad>
                  <div className={classes.newsTitle}>{node.title}</div>
                  <div className={classes.date}>
                    {this._formatDate(node.date)}
                  </div>
                </Link>
              </div>
            )
          })}
        </div>
        <div className={classNames(classes.listNews, classes.componentSkew)}>
          <table className={classes.listNewsTable}>
            {secondNewsGroup.map(({ node }: WPPost, index: number) => {
              return (
                <tr
                  key={node.id}
                  className={classNames(
                    classes.listNewsTableTr,
                    "animation",
                    "slide-in_news"
                  )}
                >
                  <td className={classes.listNewsTableTd}>
                    <Link className={classes.imgSize} to={`/news/${node.id}`}>
                      <span className={classes.date}>
                        {this._formatDate(node.date)}
                      </span>
                    </Link>
                  </td>
                  <td className={classes.listNewsTableTd}>
                    <Link className={classes.imgSize} to={`/news/${node.id}`}>
                      <span className={classes.listNewsTitle}>
                        {node.title}
                      </span>
                    </Link>
                  </td>
                </tr>
              )
            })}
          </table>
        </div>
        <div className={classNames(classes.seeAll, classes.componentSkew)}>
          <Link className={classes.seeAllLink} to={`/news`}>
            <div className={classes.seeAllText}>
              SEE
              <br />
              ALL
            </div>
          </Link>
        </div>
        <div className={classes.componentWrapper}>
          <div className={classes.follow}>
            <h3 className={classes.followLabel}>FOLLOW US!!</h3>
            <a href="https://twitter.com/nadecare" className="pd10">
              <img
                src="https://img.icons8.com/color/96/000000/twitter-squared.png"
                alt="Twitter"
              />
            </a>
            <a href="https://www.instagram.com/nadecare.jp" className="pd10">
              <img
                src="https://img.icons8.com/color/96/000000/instagram.png"
                alt="Instagram"
              />
            </a>
            <a href="https://note.com/nadecare78" className="pd10">
              <img
                src="/assets/images/note_logo_symbol.svg"
                alt="note"
                width="96"
              />
            </a>
          </div>
        </div>
      </section>
    )
  }
}

const styles: any = (theme: any) => ({
  root: {
    backgroundColor: "#FFF",
    paddingBottom: 40,
  },
  componentWrapper: {
    margin: "0 auto",
    maxWidth: 1280,
    paddingTop: 0,
  },
  topNews: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    textAlign: "center",
    flexWrap: "wrap",
  },
  img: {
    width: "280px",
    height: "280px",
    objectFit: "cover",
    marginBottom: 10,
    [theme.breakpoints.down("sm")]: {
      width: "200px",
      height: "200px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "150px",
      height: "150px",
    },
  },
  date: {
    fontFamily: "Advent Pro",
    fontStyle: "normal",
    fontStretch: "normal",
    fontSize: 20,
    letterSpacing: 1.1,
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 15,
    },
  },
  newsTitle: {
    fontFamily: "yasashisa",
    fontStyle: "normal",
    fontStretch: "normal",
    fontSize: 18,
    maxWidth: 280,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      maxWidth: 200,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 13,
      maxWidth: 150,
    },
  },
  listNews: {
    marginTop: 40,
    marginBottom: 20,
  },
  listNewsTable: {
    margin: "auto",
    maxWidth: 700,
    [theme.breakpoints.down("xs")]: {
      maxWidth: 350,
    },
  },
  listNewsTableTd: {
    paddingBottom: 4,
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 0,
    borderBottom: 0,
  },
  listNewsTitle: {
    fontFamily: "yasashisa",
    fontStyle: "normal",
    fontStretch: "normal",
    fontSize: 18,
    paddingLeft: 20,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 13,
    },
  },
  title: {
    fontFamily: "'Sawarabi Mincho', sans-serif",
    fontStyle: "normal",
    fontStretch: "normal",
    fontSize: 18,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 13,
    },
  },
  containerSkew: {
    transform: "skew(0deg, -3deg)",
  },
  componentSkew: {
    transform: "skew(0deg, 3deg)",
  },
  follow: {
    textAlign: "center",
    transform: "skew(0deg, 3deg)",
  },
  followLabel: {
    fontFamily: "'Roboto', sans-serif",
    color: "#ff4e5e",
    fontSize: 40,
  },
  seeAll: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 70,
  },
  seeAllLink: {
    width: 70,
    height: 70,
    borderRadius: "50%",
    backgroundColor: "#1e365c",
    justifyContent: "center",
    display: "inline-block",
    textAlign: "center",
  },
  seeAllText: {
    textDecoration: "none",
    color: "#fff",
    fontWeight: "bold",
    fontFamily:
      '"Roboto", -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif',
    fontSize: 15,
    transform: "translateY(-50%)",
    position: "relative",
    top: "50%",
    lineHeight: "20px",
    marginBottom: 0,
  },
})

export default withStyles(styles)(IndexNews)
