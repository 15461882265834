import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"
import classNames from "classnames"
import Image from "../image"
import { lineHeight } from "@material-ui/system"

type Props = {
  classes: any
}

class IndexAboutUs extends Component<Props> {
  render() {
    const { classes } = this.props

    return (
      <section id="about-us">
        <div className={classes.captionWrapper}>
          <h2 className={classes.caption}>
            もう一度、
            <br />
            社会の中で大きな役割を
            <br />
            果たしたい。
          </h2>
        </div>
        <div className={classes.root}>
          <div className={classes.right}>
            <div className={classes.youtubeWrap}>
              <iframe
                className={classes.youtube}
                width="560"
                height="315"
                src="https://www.youtube.com/embed/18rIA_L5IHo"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="なでケア紹介動画"
              />
            </div>
          </div>
          <div className={classes.left}>
            <div className={classes.container}>
              <div className={classes.message}>
                <p>
                  なでしこ（女子サッカー選手）が抱える、
                  <br />
                  女子サッカーへの想い、
                  <br />
                  次世代への想い、
                  <br />
                  そして、社会や地域への想い。
                </p>
              </div>
              <div className={classes.message}>
                <p>
                  そんな優しい "想い" を形にするために生まれた
                  <br />
                  プラットフォーム。
                </p>
              </div>
            </div>
            <div className={classNames(classes.label, "animation", "slide-in")}>
              それが、私たち "なでケア" です。
            </div>
          </div>
        </div>
      </section>
    )
  }
}

const styles: any = (theme: any) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    flexDirection: "row-reverse",
  },
  container: {
    paddingLeft: 40,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  captionWrapper: {
    marginLeft: "auto",
    marginRight: "auto",
    paddingLeft: 40,
    paddingBottom: 20,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 20,
    },
  },
  caption: {
    fontSize: 60,
    lineHeight: 1.4,
    letterSpacing: 20,
    [theme.breakpoints.down("sm")]: {
      fontSize: 30,
      letterSpacing: 18,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
      letterSpacing: 14,
    },
  },
  message: {
    paddingBottom: 20,
  },
  label: {
    backgroundColor: "#eee",
    fontSize: 40,
    padding: 30,
    marginBottom: 20,
    fontFamily:
      '"Sawarabi Mincho", -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif',
    lineHeight: 1.4,
    [theme.breakpoints.down("sm")]: {
      fontSize: 30,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
    },
  },
  left: {
    marginRight: "auto",
    marginLeft: "auto",
  },
  right: {
    marginRight: "auto",
    marginLeft: "auto",
    marginBottom: 40,
    width: 560,
  },
  youtubeWrap: {
    position: "relative",
    paddingBottom: "56.25%",
  },
  youtube: {
    position: "absolute",
    top: 0,
    right: 0,
    width: "100%",
    height: "100%",
  },
})

export default withStyles(styles)(IndexAboutUs)
